import React, { Component, lazy } from 'react';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import FocusLayout from './FocusLayout';
import PublicLayout from '../containers/PublicLayout';
import Login from '../scenes/Login/Login';
import MfaVerification from '../containers/MfaVerification';
import MfaRegistration from '../containers/MfaRegistration';
import { BillSidebarTabs } from '../helpers/BillHelper';
import { openOptionalLoginModal } from '../dux/ModalDux';

const RegistrationContainer = lazy(() =>
  import('../scenes/Registration/RegistrationContainer'),
);
const LandingPageContainer = lazy(() =>
  import('../scenes/Registration/LandingPageContainer'),
);
const SignupForm = lazy(() => import('../scenes/Registration/SignupForm'));
const EmailSignupForm = lazy(() =>
  import('../scenes/Registration/Onboarding/EmailSignupForm'),
);
const TrialRequestForm = lazy(() =>
  import('../scenes/Registration/SignupRequest/TrialRequestForm'),
);
const SignupRequestForm = lazy(() =>
  import('../scenes/Registration/SignupRequest/SignupRequestForm'),
);
const TrialSignupForm = lazy(() => import('../scenes/Registration/TrialSignupForm'));
const FreeSignupForm = lazy(() => import('../scenes/Registration/NewSignupForm'));
const ForgotPasswordReset = lazy(() => import('../containers/ForgotPasswordReset'));
const ForgotPassword = lazy(() => import('../scenes/Login/ForgotPassword'));
const TaggedBills = lazy(() => import('../scenes/BillTags/TaggedBills'));
const NeedToBeLoggedInContainer = lazy(() => import('../containers/NeedToBeLoggedIn'));
const OptionalLoginContainer = lazy(() =>
  import('../containers/OptionalLoginContainer'),
);
const BillView = lazy(() => import('../scenes/BillView/BillView'));
const PersonView = lazy(() => import('../scenes/Person/PersonView'));
const AdvBillSearch = lazy(() => import('../scenes/LegislativeTracking/AdvBillSearch'));
const JurisdictionDetails = lazy(() =>
  import('../scenes/Jurisdictions/JurisdictionDetails.tsx'),
);
const SsoAuthCallback = lazy(() => import('../scenes/Login/SsoAuthCallback'));
// TODO: Add sso to main login page after testing
const LoginSsoTest = lazy(() => import('../scenes/Login/LoginSsoTest'));
// haydn: I think this is required because specifying a render does
// not properly assign the router even inside a switch
const LoginWithRouter = withRouter(Login);
const LoginSsoTestWithRouter = withRouter(LoginSsoTest);
const MfaVerificationWithRouter = withRouter(MfaVerification);
const MfaRegistrationWithRouter = withRouter(MfaRegistration);
const SentryRoute = Sentry.withSentryRouting(Route);

class LoggedOutRouter extends Component {
  render() {
    const { children, openRestrictionModal, openOptionalLoginModal } = this.props;
    return (
      <div>
        <Switch>
          <SentryRoute
            path="/login"
            render={() => (
              <RegistrationContainer>
                <LoginWithRouter />
              </RegistrationContainer>
            )}
          />
          <SentryRoute
            path="/mfa-verification"
            render={() => (
              <FocusLayout>
                <MfaVerificationWithRouter />
              </FocusLayout>
            )}
          />
          <SentryRoute
            path="/mfa-registration"
            render={() => (
              <FocusLayout>
                <MfaRegistrationWithRouter isRequiredByAdmin />
              </FocusLayout>
            )}
          />
          <SentryRoute
            path="/get-started"
            render={(props) => (
              <RegistrationContainer>
                <EmailSignupForm />
              </RegistrationContainer>
            )}
          />
          <SentryRoute
            path="/free-trial/:token"
            render={(props) => (
              <RegistrationContainer>
                <TrialSignupForm authToken={props.match.params.token} />
              </RegistrationContainer>
            )}
          />
          <SentryRoute
            path="/free-trial"
            render={(props) => (
              <LandingPageContainer overridePageWidth={true}>
                <TrialRequestForm />
              </LandingPageContainer>
            )}
          />
          <SentryRoute
            path="/create-account/:token"
            render={(props) => (
              <RegistrationContainer viewHeight="5vh">
                <FreeSignupForm authToken={props.match.params.token} />
              </RegistrationContainer>
            )}
          />
          <SentryRoute
            path="/create-account"
            render={(props) => (
              <LandingPageContainer>
                <SignupRequestForm />
              </LandingPageContainer>
            )}
          />
          <SentryRoute
            path="/sign-up/:token"
            render={(props) => (
              <RegistrationContainer>
                <SignupForm authToken={props.match.params.token} />
              </RegistrationContainer>
            )}
          />
          <SentryRoute
            path="/forgot-password"
            render={(props) => (
              <FocusLayout>
                <ForgotPassword />
              </FocusLayout>
            )}
          />
          <SentryRoute
            path="/password-reset/:token"
            render={(props) => (
              <FocusLayout>
                <ForgotPasswordReset {...props} />
              </FocusLayout>
            )}
          />
          <SentryRoute
            exact
            path="/legislative-tracking/bill/details/bill-activity/:billId/:billVersionId?"
            render={(props) => {
              return (
                <PublicLayout>
                  <div className="legislative-tracking-wrapper">
                    <div id="content">
                      <BillView
                        billId={props.match.params.billId}
                        billVersionId={props.match.params.billVersionId}
                        defaultTabOnLoad={BillSidebarTabs.ACTIVITY}
                      />
                    </div>
                  </div>
                </PublicLayout>
              );
            }}
          />
          <SentryRoute
            exact
            path="/legislative-tracking/bill/details/bill-intelligence/:billId/:billVersionId?"
            render={(props) => {
              return (
                <PublicLayout>
                  <div className="legislative-tracking-wrapper">
                    <div id="content">
                      <BillView
                        billId={props.match.params.billId}
                        billVersionId={props.match.params.billVersionId}
                        defaultTabOnLoad={BillSidebarTabs.DETAILS}
                      />
                    </div>
                  </div>
                </PublicLayout>
              );
            }}
          />
          <SentryRoute
            exact
            path="/legislative-tracking/bill/details/federal-usa-:billPartialId/:billVersionId?"
            render={(props) => {
              // Open States re-router introduced a bad route in its initial logic
              // bad:  /legislative-tracking/bill/details/federal-usa-118-hr4778
              // good: /legislative-tracking/bill/details/federal-118-hr4778
              const path = props.match.url;
              return <Redirect to={path.replace('federal-usa-', 'federal-')} />;
            }}
          />
          <SentryRoute
            exact
            path="/legislative-tracking/bill/details/:billId/:billVersionId?"
            render={(props) => {
              return (
                <PublicLayout>
                  <div className="legislative-tracking-wrapper">
                    <div id="content">
                      <BillView
                        billId={props.match.params.billId}
                        billVersionId={props.match.params.billVersionId}
                      />
                    </div>
                  </div>
                </PublicLayout>
              );
            }}
          />
          <SentryRoute
            exact
            path="/legislative-tracking/tagged-bills/:tagId"
            render={(props) => (
              <PublicLayout>
                <div className="legislative-tracking-wrapper">
                  <div id="content">
                    <TaggedBills tagId={Number(props.match.params.tagId)} />
                  </div>
                </div>
              </PublicLayout>
            )}
          />
          <SentryRoute
            path="/legislative-tracking"
            render={(props) => (
              <PublicLayout>
                <AdvBillSearch />
              </PublicLayout>
            )}
          />
          <SentryRoute
            path="/person/:electedOfficialId"
            render={(props) => (
              <PublicLayout>
                <PersonView
                  electedPositionId={props.match.params.electedOfficialId}
                  key={props.match.params.electedOfficialId}
                />
              </PublicLayout>
            )}
          />
          <SentryRoute
            path="/tagged-bills/:tagId"
            render={(props) => (
              <PublicLayout hideBanner>
                <TaggedBills tagId={Number(props.match.params.tagId)} />
              </PublicLayout>
            )}
          />
          <SentryRoute
            path="/jurisdictions/:jurisAbbr"
            render={(props) => (
              <PublicLayout>
                <JurisdictionDetails jurisAbbr={props.match.params.jurisAbbr} />
              </PublicLayout>
            )}
          />
          <SentryRoute path="/sso-auth-callback" component={SsoAuthCallback} />
          {/* TODO: Move this to login page after testing */}
          <SentryRoute
            path="/login-internal-sso-test/7f43076b-80df-4830-9be8-3f3b5052aa66/:errorCode?"
            render={(props) => (
              <RegistrationContainer>
                <LoginSsoTestWithRouter errorCode={props.match.params.errorCode} />
              </RegistrationContainer>
            )}
          />
          <Redirect
            from="/"
            to={{
              pathname: '/login',
              state: { referrer: this.props.activePath },
            }}
          />
        </Switch>
        {openRestrictionModal && <NeedToBeLoggedInContainer />}
        {children}
      </div>
    );
  }
}

LoggedOutRouter.propTypes = {
  children: PropTypes.node,
  openRestrictionModal: PropTypes.bool,
  openOptionalLoginModal: PropTypes.bool,
};

export default LoggedOutRouter;
