import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Logo from './Logo';
import { g as abilityGlossary } from '../config/ability';
import NewTopNav from '../scenes/PrimaryView/NavRedesign/TopNav/NewTopNav';
import featureFlags from '../featureFlags';
import NewPrimarySidebar from '../scenes/PrimaryView/NavRedesign/NewPrimarySidebar';
import React, { Component } from 'react';
import OptionalLoginContainer from '../containers/OptionalLoginContainer';

class PublicLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      sidebarMinimized: true,
    };
  }

  componentDidMount() {
    const useNewLoggedOutNav = featureFlags.getDisplayLoggedOutUpdatedNav();
    this.setState({ useNewLoggedOutNav });
  }

  toggleMenuOpen = () => {
    this.setState((prevState) => ({
      menuOpen: !prevState.menuOpen,
    }));
  };

  toggleSidebarMinimized = () => {
    this.setState((prevState) => ({
      sidebarMinimized: !prevState.sidebarMinimized,
    }));
  };

  renderBannerContent() {
    return (
      <span>
        Like what you see?{' '}
        <a
          className="public-access-banner"
          onClick={() =>
            this.props.onCheckPermission(
              abilityGlossary.CREATE,
              abilityGlossary.BILL_ANNOTATION,
            )
          }
        >
          <strong>Schedule a Demo!</strong>
        </a>
      </span>
    );
  }

  render() {
    const { menuOpen, sidebarMinimized } = this.state;
    const useNewLoggedOutNav = featureFlags.getDisplayLoggedOutUpdatedNav();
    const bannerContent = this.renderBannerContent();
    const layoutClasses = ['primary-layout'];

    if (bannerContent && this.props.showBanner) {
      layoutClasses.push('with-banner');
    }

    const dropDownClass = menuOpen
      ? 'dropdown desktop-menu active'
      : 'dropdown desktop-menu inactive';

    const oldNav = (
      <div className={layoutClasses.join(' ')}>
        <div className="site-banner">{bannerContent}</div>
        <nav className="ce-nav">
          <div className="branding">
            <Link to="/legislative-tracking">
              <Logo />
            </Link>
          </div>
          <div className="right-nav">
            <ul>
              <li className="help-link">
                <Link to="/legislative-tracking">Search</Link>
              </li>
              <li className="help-link">
                <Link to="/teams">Teams</Link>
              </li>
              <li className={dropDownClass} onClick={this.toggleMenuOpen}>
                <span className="desktop-menu-label">My Account</span>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  color="#979797"
                  className="mobile-menu-icon"
                  onClick={this.toggleSidebarMinimized}
                />
                <ul>
                  <li>
                    <Link style={{ color: '#7f8fa4' }} to="/login">
                      Log In
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="help-link">
                <a
                  href="https://help.pluralpolicy.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Help
                </a>
              </li>
            </ul>
          </div>
        </nav>
        <div className={`primary-layout-wrapper sidebar-minimized`}>
          <div className="page-layout__viewport primary-layout-content sidebar-minimized">
            {this.props.children}
            <p className="text-center mt-3">
              <small>© Plural</small>
            </p>
          </div>
        </div>
      </div>
    );

    const newNav = (
      <div className={layoutClasses.join(' ')}>
        <div className="site-banner">{bannerContent}</div>
        <NewTopNav
          setSidebarMinimized={this.toggleSidebarMinimized}
          sidebarMinimized={sidebarMinimized}
        />
        <div
          className={`primary-layout-wrapper${
            sidebarMinimized ? ' sidebar-minimized' : ''
          } d-flex flex-row`}
        >
          <NewPrimarySidebar
            minimize={this.toggleSidebarMinimized}
            isMinimized={sidebarMinimized}
          />
          <div
            className={`page-layout__viewport primary-layout-content ${
              sidebarMinimized ? ' sidebar-minimized' : ''
            } d-flex flex-column`}
          >
            <div className="flex-grow-1">{this.props.children}</div>
            <footer className="text-center m-3">
              <small>© Plural</small>
            </footer>
          </div>
        </div>
        {this.props.openOptionalLoginModal && <OptionalLoginContainer />}
      </div>
    );

    if (useNewLoggedOutNav) return newNav;
    return oldNav;
  }
}

PublicLayout.propTypes = {
  children: PropTypes.any,
  hideBanner: PropTypes.bool,
  onCheckPermission: PropTypes.func.isRequired,
  organizationUser: PropTypes.object,
  openOptionalLoginModal: PropTypes.bool,
};

export default PublicLayout;
